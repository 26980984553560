@use '@hop/styles/var';
@import '~simplebar/dist/simplebar.css';
@import '~highlight.js/styles/androidstudio.css';
// Hop Core
@import './@hop/styles/core';
@import 'ngx-toastr/toastr';

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @hop/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  // --color-primary: blue;
  // --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;

  .snackbar-primary {
    .mdc-snackbar__surface {
      background: rgb(var(--color-primary));
      .mat-mdc-snack-bar-label {
        color: rgb(var(--color-primary-contrast));
      }
    }
  }

  .snackbar-warn {
    .mdc-snackbar__surface {
      background: rgba(var(--color-warn));
      .mat-mdc-snack-bar-label {
        color: rgb(var(--color-warn-contrast));
      }
    }
  }

  .snackbar-accent {
    .mdc-snackbar__surface {
      .mat-mdc-snack-bar-label {
        color: rgb(var(--color-accent-contrast));
      }
      background: rgb(var(--color-accent));
    }
  }

  /*.hop-style-dark {
    .snackbar-primary {
      .mdc-snackbar__surface {
        background: rgb(var(--color-primary-contrast));
      }
    }

    .snackbar-warn {
      .mdc-snackbar__surface {
        background: rgba(var(--color-warn-contrast));
        color: whitesmoke;
      }
    }

    .accent-snackbar {
      .mdc-snackbar__surface {
        background: rgb(var(--color-accent-contrast));
        color: whitesmoke;
      }
    }
  }*/

  .custom-modalbox-hop-calendar {
    max-width: 100% !important;
    max-height: 100% !important;
    width: 90%;
    height: 80%;
  }

  @media (min-width: 750px) {
    .custom-modalbox-hop-calendar {
      width: 57%;
      height: 80%;
    }
  }

  .toast-success {
    background-color: #21a221;
  }
  .toast-error {
    background-color: rgb(var(--color-warn));
  }
  .toast-info {
    background-color: rgb(var(--color-primary));
  }
  .toast-warning {
    background-color: rgb(var(--color-accent));
  }

  .material-symbols-outlined {
    font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48;
  }

  body.page-onboard {
    overflow-y: auto;
    background: var(--background-base);
  }

  .psm__progress-bar-overlay {
    &.background-color-ok {
      background-color: #21a221;
    }
    &.background-color-not-ok {
      background-color: rgb(var(--color-warn));
    }
  }
  @media (min-width: 750px) {
    .custom-modalbox-hop-calendar {
      width: 57%;
      height: 80%;
    }
  }

  @media (max-width: 600px) {
    mat-form-field {
      .mat-mdc-form-field-error-wrapper {
        position: static;
        margin-top: -22px;
      }
    }
  }
}
