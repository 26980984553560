:root {
  .mat-mdc-button, .mat-mdc-outlined-button, .mat-mdc-unelevated-button, .mat-mdc-raised-button {
    @apply rounded-button min-w-[6rem];
  }

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle-group:not(.mat-button-toggle-vertical) {
    @apply rounded-button;
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    .mat-button-toggle:first-child .mat-button-toggle-label-content {
      @apply pl-4;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    .mat-button-toggle:last-child .mat-button-toggle-label-content {
      @apply pr-4;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle-button {
    @apply font-medium;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
