@use '@angular/material' as mat;
@use '../../var';
@use '@angular/material/theming';
@use '../mixins';

$hop-primary: mat.define-palette(
  (
    100: var(--color-primary-contrast),
    500: var.$color-primary,
    700: var(--color-primary),
    contrast: ()
  ),
  500
) !default;

$hop-accent: mat.define-palette(
  (
    100: var(--color-accent-contrast),
    500: var.$color-accent,
    700: var(--color-accent),
    contrast: (
      100: rgba(var(--color-accent-contrast), 1),
      500: rgba(var(--color-accent-contrast), 1),
      700: rgba(var(--color-accent-contrast), 1)
    )
  )
) !default;

// The warn palette is optional (defaults to red).
$hop-warn: mat.define-palette(
  (
    100: var(--color-warn-contrast),
    500: var.$color-warn,
    700: var(--color-warn),
    contrast: (
      100: rgba(var(--color-warn-contrast), 1),
      500: rgba(var(--color-warn-contrast), 1),
      700: rgba(var(--color-warn-contrast), 1)
    )
  )
) !default;

$hop-theme: mat.define-light-theme(
  (
    color: (
      primary: $hop-primary,
      accent: $hop-accent,
      warn: $hop-warn
    ),
    typography: mat.define-typography-config(),
    density: 0
  )
);

// @formatter:off
:root {
  // Generic
  --padding-gutter: 1rem;
  --padding: 1.5rem;
  --padding-16: 1rem;
  --padding-12: 0.75rem;
  --padding-8: 0.5rem;
  --padding-6: 0.375rem;
  --padding-4: 0.25rem;

  --color-primary: var.$color-primary;
  --color-primary-contrast: var.$color-primary-contrast;

  --color-accent: var.$color-accent;
  --color-accent-contrast: var.$color-accent-contrast;

  --color-warn: var.$color-warn;
  --color-warn-contrast: var.$color-warn-contrast;

  --mdc-switch-selected-track-color: #ff4081ff;
  --mdc-switch-selected-hover-track-color: #e88fa9;
  --mdc-switch-selected-focus-track-color: #e88fa9;
  --mdc-switch-selected-handle-color: #ff4081ff;
  --mdc-switch-selected-focus-handle-color: #e88fa9;
  --mdc-switch-selected-hover-handle-color: #e88fa9;
  --mdc-switch-selected-pressed-track-color: #e88fa9;
  --mdc-switch-selected-pressed-handle-color: #e88fa9;
  --mdc-switch-selected-pressed-state-layer-color: #e88fa9;

  @screen lg {
    --padding-gutter: 1.5rem;
  }

  // Typography
  --font: theme('fontFamily.mono');
  --font-weight-medium: 500;
  --font-caption: #{mat.font-weight(var.$config, caption) #{mat.font-size(var.$config, caption)}/#{mat.line-height(var.$config, caption)}
    mat.font-family(var.$config, caption)};
  --font-body-1: #{mat.font-weight(var.$config, body-1) #{mat.font-size(var.$config, body-1)}/#{mat.line-height(var.$config, body-1)}
    mat.font-family(var.$config, body-1)};
  --font-body-2: #{mat.font-weight(var.$config, body-2) #{mat.font-size(var.$config, body-2)}/#{mat.line-height(var.$config, body-2)}
    mat.font-family(var.$config, body-2)};
  --font-subtitle-1: #{mat.font-weight(var.$config, subtitle-1) #{mat.font-size(var.$config, subtitle-1)}/#{mat.line-height(var.$config, subtitle-1)}
    mat.font-family(var.$config, subtitle-1)};
  --font-subtitle-2: #{mat.font-weight(var.$config, subtitle-2) #{mat.font-size(var.$config, subtitle-2)}/#{mat.line-height(var.$config, subtitle-2)}
    mat.font-family(var.$config, subtitle-2)};
  --font-headline-1: #{mat.font-weight(var.$config, headline-1) #{mat.font-size(var.$config, headline-1)}/#{mat.line-height(var.$config, headline-1)}
    mat.font-family(var.$config, headline-1)};
  --font-headline-4: #{mat.font-weight(var.$config, headline-4) #{mat.font-size(var.$config, headline-4)}/#{mat.line-height(var.$config, headline-4)}
    mat.font-family(var.$config, headline-4)};
  --font-headline-6: #{mat.font-weight(var.$config, headline-6) #{mat.font-size(var.$config, headline-6)}/#{mat.line-height(var.$config, headline-6)}
    mat.font-family(var.$config, headline-6)};
  // Transitions
  --trans-ease-in-out: all var(--trans-ease-in-out-duration) var(--trans-ease-in-out-timing-function);
  --trans-ease-in-out-duration: #{theming.$swift-ease-in-out-duration};
  --trans-ease-in-out-timing-function: #{theming.$swift-ease-in-out-timing-function};
  --trans-ease-out: all var(--trans-ease-out-duration) var(--trans-ease-out-timing-function);
  --trans-ease-out-duration: #{theming.$swift-ease-out-duration};
  --trans-ease-out-timing-function: #{theming.$swift-ease-out-timing-function};
  --trans-ease-in: all var(--trans-ease-in-duration) var(--trans-ease-in-timing-function);
  --trans-ease-in-duration: #{theming.$swift-ease-in-duration};
  --trans-ease-in-timing-function: #{theming.$swift-ease-in-timing-function};
  --trans-shadow-duration: 280ms;
  --trans-shadow-timing-function: #{cubic-bezier(0.4, 0, 0.2, 1)};
  --trans-shadow: box-shadow var(--trans-shadow-duration) var(--trans-shadow-timing-function);

  --text-color: #{var.$dark-primary-text};
  --text-color-light: #{var.$light-primary-text};
  --text-secondary: #{var.$dark-secondary-text};
  --text-secondary-light: #{var.$light-secondary-text};
  --text-hint: #{var.$dark-disabled-text};
  --text-hint-light: #{var.$light-disabled-text};

  // Foreground
  --foreground-divider: #{map-get(map-get($hop-theme, foreground), divider)};

  // Background
  --background-base: rgb(245, 245, 248);
  --background-foreground: #{map-get(map-get($hop-theme, background), card)};
  --background-foreground-rgb: #{red(map-get(map-get($hop-theme, background), card))}, #{green(map-get(map-get($hop-theme, background), card))},
    #{blue(map-get(map-get($hop-theme, background), card))};
  --background-app-bar: #{map-get(map-get($hop-theme, background), app-bar)};
  --background-hover: #{map-get(map-get($hop-theme, background), hover)};

  // Elevation
  --elevation-default: var(--elevation-z6);
  --elevation-z0: none;
  --elevation-z1: #{mixins.hop-elevation(1)};
  --elevation-z2: #{mixins.hop-elevation(2)};
  --elevation-z3: #{mixins.hop-elevation(3)};
  --elevation-z4: #{mixins.hop-elevation(4)};
  --elevation-z5: #{mixins.hop-elevation(5)};
  --elevation-z6: #{mixins.hop-elevation(6)};
  --elevation-z7: #{mixins.hop-elevation(7)};
  --elevation-z8: #{mixins.hop-elevation(8)};
  --elevation-z9: #{mixins.hop-elevation(9)};
  --elevation-z10: #{mixins.hop-elevation(10)};
  --elevation-z11: #{mixins.hop-elevation(11)};
  --elevation-z12: #{mixins.hop-elevation(12)};
  --elevation-z13: #{mixins.hop-elevation(13)};
  --elevation-z14: #{mixins.hop-elevation(14)};
  --elevation-z15: #{mixins.hop-elevation(15)};
  --elevation-z16: #{mixins.hop-elevation(16)};
  --elevation-z17: #{mixins.hop-elevation(17)};
  --elevation-z18: #{mixins.hop-elevation(18)};
  --elevation-z19: #{mixins.hop-elevation(19)};
  --elevation-z20: #{mixins.hop-elevation(20)};

  // Sidenav
  --sidenav-width: 280px;
  --sidenav-collapsed-width: 72px;
  --sidenav-background: #{var.$sidenav-background};
  --sidenav-color: white;

  --sidenav-toolbar-background: #{darken(var.$sidenav-background, 1.5)};
  --sidenav-toolbar-background-rgb: #{red(darken(var.$sidenav-background, 1.5))}, #{green(darken(var.$sidenav-background, 1.5))},
    #{blue(darken(var.$sidenav-background, 1.5))};
  // --sidenav-section-divider-color: #{map-get(map-get($hop-dark-theme, foreground), divider)};

  // Sidenav Item
  --sidenav-item-min-height: 48px;
  --sidenav-item-padding-vertical: var(--padding-8);
  --sidenav-item-padding-horizontal: var(--padding);
  --sidenav-item-background-hover: #{darken(var.$sidenav-background, 3)};
  --sidenav-item-background-active: #{darken(var.$sidenav-background, 3)};
  --sidenav-item-font-weight: 500;
  --sidenav-item-color: #a1a2b6;
  --sidenav-item-color-hover: #{var.$light-primary-text};
  --sidenav-item-color-active: #{var.$light-primary-text};
  --sidenav-item-icon-color: #494b74;
  --sidenav-item-icon-color-hover: rgb(var(--color-primary));
  --sidenav-item-icon-color-active: rgb(var(--color-primary));
  --sidenav-item-icon-gap: 16px;
  --sidenav-item-icon-size: 24px;
  --sidenav-item-dropdown-background: #{darken(var.$sidenav-background, 3)};
  --sidenav-item-dropdown-background-hover: #{darken(var.$sidenav-background, 4)};
  --sidenav-item-dropdown-color-hover: var(--sidenav-item-color-hover);
  --sidenav-item-dropdown-background-active: var(--sidenav-item-dropdown-background-hover);
  --sidenav-item-dropdown-color-active: var(--sidenav-item-color-active);
  --sidenav-item-ripple-color: #{rgb(map-get(map-get($hop-theme, foreground), base), 0.1)};

  --sidenav-item-dropdown-gap: 12px;
  --sidenav-subheading-color: #6b6e7f;

  // Toolbar
  --toolbar-height: 64px;
  --toolbar-background: white;
  --toolbar-color: #{var.$dark-primary-text};
  --toolbar-icon-color: rgb(var(--color-primary));

  // Secondary Toolbar
  --secondary-toolbar-background: var(--background-foreground);
  --secondary-toolbar-height: 54px;

  // Navigation
  --navigation-height: 64px;
  --navigation-background: var(--background-foreground);
  --navigation-color: var(--text-secondary);

  // Footer
  --footer-height: 56px;
  --footer-z-index: 100;
  --footer-background: var(--background-foreground);
  --footer-color: var(--text-color);
  --footer-elevation: 0 -10px 30px 0 rgba(82, 63, 104, 0.06);

  // Page Layouts
  --page-layout-header-height: 200px;
  --page-layout-toolbar-height: 64px;

  // Misc
  --blink-scrollbar-width: 12px;
  --default-icon-size: 24px;
  --border-radius: 0.25rem;
}
// @formatter:on
