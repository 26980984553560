@use '@angular/material' as mat;
@use "../../var";
@use "./style-default";
$hop-primary-dark: mat.define-palette((
  100: var(--color-primary-contrast),
  500: var.$color-primary,
  700: var(--color-primary),
  contrast: (
  )
), 500) !default;

$hop-dark-theme-background: (
  background: lighten(var.$sidenav-background, 5),
  card: var.$sidenav-background,
  app-bar: darken(var.$sidenav-background, 5),
  dialog: var.$sidenav-background,
  status-bar: darken(var.$sidenav-background, 5)
) !default;

$hop-dark-theme: mat.define-dark-theme((
  color: (
    primary: $hop-primary-dark,
    accent: style-default.$hop-accent,
    warn: style-default.$hop-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

.hop-style-dark {
  @include mat.all-component-colors($hop-dark-theme);

  // Foreground
  --background-app-bar: #{map-get(map-get($hop-dark-theme, background), app-bar)};

  // Background
  --footer-background: var(--background-foreground);
  --navigation-background: #{map-get(map-get($hop-dark-theme, background), card)};
  --toolbar-background: #{map-get(map-get($hop-dark-theme, background), card)};
  --background-base: #{map-get(map-get($hop-dark-theme, background), background)};

  // Colors
  --background-foreground: #{map-get(map-get($hop-dark-theme, background), card)};
  --background-foreground-rgb: #{red(map-get(map-get($hop-dark-theme, background), card))}, #{green(map-get(map-get($hop-dark-theme, background), card))}, #{blue(map-get(map-get($hop-dark-theme, background), card))};
  --footer-color: var(--text-color);
  --navigation-color: var(--text-color);
  --text-color: #{var.$light-primary-text};
  --toolbar-color: #{var.$light-primary-text};
  --text-color-light: #{var.$dark-primary-text};

  // Toolbar
  --foreground-divider: #{map-get(map-get($hop-dark-theme, foreground), divider)};
  --text-hint: #{var.$light-disabled-text};

  // Navigation
  --text-hint-light: #{var.$dark-disabled-text};
  --background-hover: #{map-get(map-get($hop-dark-theme, background), hover)};

  // Secondary Toolbar
  --text-secondary: #{var.$light-secondary-text};

  // Footer
  --text-secondary-light: #{var.$dark-secondary-text};
  --secondary-toolbar-background: var(--background-foreground);
}
