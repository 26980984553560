@use "../../var";
@use '@angular/material' as mat;

/*$hop-primary-light: mat.define-palette((
  100: var(--color-primary-contrast),
  500: var.$color-primary,
  700: var(--color-primary),
  contrast: (
  )
), 500) !default;

$hop-accent-light: mat.define-palette((
  100: var(--color-accent-contrast),
  500: var.$color-accent,
  700: var(--color-accent),
  contrast: (
    100: rgba(var(--color-accent-contrast), 1),
    500: rgba(var(--color-accent-contrast), 1),
    700: rgba(var(--color-accent-contrast), 1),
  )
)) !default;

// The warn palette is optional (defaults to red).
$hop-warn-light: mat.define-palette((
  100: var(--color-warn-contrast),
  500: var.$color-warn,
  700: var(--color-warn),
  contrast: (
    100: rgba(var(--color-warn-contrast), 1),
    500: rgba(var(--color-warn-contrast), 1),
    700: rgba(var(--color-warn-contrast), 1),
  )
)) !default;

$hop-theme-light: mat.define-light-theme((
  color: (
    primary: $hop-primary-light,
    accent: $hop-accent-light,
    warn: $hop-warn-light,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));*/

.hop-style-light {
  //@include mat.all-component-colors($hop-theme-light);

  $sidenav-background: white;

  --navigation-background: var(--background-foreground);
  --sidenav-background: #{$sidenav-background};

  // Sidenav
  --toolbar-background: var(--background-foreground);
  --sidenav-color: var(--text-color);

  // Sidenav Item
  --sidenav-item-background-hover: #{darken($sidenav-background, 5)};
  --sidenav-item-background-active: #{darken($sidenav-background, 3)};
  --sidenav-item-color: var(--text-color);
  --sidenav-item-color-hover: var(--text-color);
  --sidenav-item-color-active: var(--text-color);
  --sidenav-item-dropdown-background: #{darken($sidenav-background, 3)};
  --sidenav-item-dropdown-background-hover: #{darken($sidenav-background, 8)};
  --sidenav-item-dropdown-color-hover: var(--text-color);
  --sidenav-item-dropdown-background-active: #{darken($sidenav-background, 10)};
  --sidenav-item-dropdown-color-active: var(--text-color);
  --sidenav-item-icon-color-active: rgb(var(--color-primary));
  --sidenav-toolbar-background: #{$sidenav-background};
  --sidenav-toolbar-background-rgb: #{red($sidenav-background)}, #{green($sidenav-background)}, #{blue($sidenav-background)};
  --sidenav-section-divider-color: #{map-get(var.$hop-theme-foreground, divider)};

  .sidenav {
    @apply border-r border-r-gray-200;

    .hop-sidenav-user {
      .hop-sidenav-user__subtitle,
      .hop-sidenav-user__dropdown-icon {
        @apply text-contrast-black/50;
      }

      &:hover {
        @apply bg-gray-100 text-gray-900;
      }
    }

    .hop-sidenav-search {
      @apply bg-gray-100 text-gray-600;

      &:hover {
        @apply bg-gray-200 text-gray-900;
      }
    }
  }

  .hop-sidenav-user--open {
    @apply bg-gray-100;
  }
}
