@font-face {
  font-family: 'Monospaced';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url('/assets/fonts/SpaceMono-Regular.ttf') format('ttf');
  font-named-instance: 'Regular';
}

@font-face {
  font-family: 'Monospaced';
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url('/assets/fonts/SpaceMono-Italic.ttf') format('ttf');
  font-named-instance: 'Italic';
}